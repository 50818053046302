import React, { PureComponent } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { TimelineLite } from 'gsap';
import { Doughnut } from 'react-chartjs-2';
import Observer from 'react-intersection-observer';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import ReadMore from '../../static/assets/Readmore.svg';
import 'chartjs-plugin-datalabels';
import 'chartjs-plugin-deferred';
import LightBluekey from '../../static/assets/light_blue_key.svg';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import '../sass/_working-with.scss';

import * as globalAnimation from '../animations/globalAnimation';
import animateLandingSection from '../animations/WorkingWith';

const data = {
  labels: ['PhD', 'MPH', 'MBA', 'Bachelors', 'Other Masters'],
  datasets: [
    {
      label: 'Projects Done in 2018',
      backgroundColor: ['#f4ba01', '#0a4169', '#57b7f9', '#C32A1F', '#8e44ad'],
      borderColor: 'rgba(0,10,220,0)',
      data: [22, 18, 12, 22, 26],
      hoverBackgroundColor: ['#f4ba01', '#0a4169', '#57b7f9', '#C32A1F', '#8e44ad'],
    },
  ],
};

export default class workingWith extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.tl = new TimelineLite({ force3D: !0, yoyo: true });
  }

  componentDidMount() {
    globalAnimation.animateHeading(this.tl, '.animate-title-one', '.animate-subtitle-one');
    animateLandingSection(
      this.tl,
      '.svg-line__line_lightblue',
      '.svg_circle_lightblue',
      '.animate-up',
      '.animate-fade-up',
      '.project_container',
      '.animate-btn'
    );
  }

  animateLearnMore = InView => {
    const tl1 = new TimelineLite({ force3D: !0, yoyo: true });
    if (InView) {
      globalAnimation.fadeUp(tl1, '.animate-learn-more');
    }
  };

  animateSecondTitle = InView => {
    const tl2 = new TimelineLite({ force3D: !0, yoyo: true });
    if (InView) {
      globalAnimation.animateHeading(tl2, '.animate-title-two', '.animate-subtitle-two');
    }
  };

  animateTitleTwo = InView => {
    const tl3 = new TimelineLite({ force3D: !0, yoyo: true });
    if (InView) {
      globalAnimation.animateHeading(tl3, '.animate-title-three', '.animate-subtitle-three');
    }
  };

  animateFieldsOfStudy = InView => {
    const tl4 = new TimelineLite({ force3D: !0, yoyo: true });
    if (InView) {
      globalAnimation.fadeUp(tl4, '.animate-fields-of-study');
    }
  };

  render() {
    const {
      data: {
        markdownRemark: {
          frontmatter: { title, description, chart, universities, fields },
        },
      },
    } = this.props;
    return (
      <Layout navbarColor="appcolor">
        <SEO pageTitle="Who will I be working with and learning from? | Our Opportunities" />
        <Container fluid className="main_wrapper section-one">
          <Row>
            <Col>
              <div className="wrapper working-with">
                <div className="page_heading_section text-center">
                  <h3 className="page_heading text-uppercase d-lg-block d-none title animate-title-one">
                    {title}
                  </h3>
                  <h4 className="text-uppercase page_heading_child subtitle w-auto animate-subtitle-one">
                    {title}
                  </h4>
                </div>
              </div>
              <div className="wrapper pt-lg-0">
                <Row>
                  <Col>
                    <div className="py-lg-5 animate-up">
                      <p>{description}</p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={4} lg={4} className="d-flex align-items-center">
                    <div id="canvas-holder" className="position-relative canvas_holder mb-2">
                      <Doughnut
                        id="chart-area"
                        data={data}
                        height={90}
                        width={90}
                        options={{
                          legend: {
                            display: false,
                          },
                          cutoutPercentage: 70,
                          tooltips: {
                            enabled: true,
                          },
                          responsive: true,
                          plugins: {
                            datalabels: {
                              display: false,
                            },
                          },
                        }}
                      />
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={{ span: 7, offset: 1 }}>
                    <div className="d-flex align-items-center flex-column">
                      <div>
                        <LightBluekey width="100px" />
                        <p className="animate-fade-up">{chart.description}</p>
                      </div>
                    </div>
                    <div className="d-flex flex-row">
                      <Row>
                        {chart.data.map(content => (
                          <Col xs={6} lg={content.span}>
                            <div className="project_container">
                              <div
                                className={`data_container px-3 position-relative ${content.class}`}
                              >
                                <p className="text-black-50">{content.title}</p>
                                <h3>{content.per}</h3>
                              </div>
                            </div>
                          </Col>
                        ))}
                        {/* <Col xs={6} lg={4}>
                          <div className="project_container">
                            <div className="data_container px-3 position-relative other_masters">
                              <p className="text-black-50">Other Masters</p>
                              <h3>26%</h3>
                            </div>
                          </div>
                        </Col> */}
                      </Row>
                    </div>

                    <div className="mt-3 animate-learn-more pie-btn-wrapper">
                      <div className="page_heading_section">
                        <Link
                          to="/team-bios/"
                          className="learn_more bordered-btn btn-long d-inline-block text-center animate-btn"
                        >
                          Learn more about our senior leadership
                          <ReadMore className="arrow-translate mb-0" height="10px" width="40px" />
                        </Link>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
        <Container fluid className="main_wrapper section-two bg-grey mt-lg-0">
          <Row>
            <Col>
              <Observer onChange={this.animateSecondTitle} triggerOnce>
                <div className="wrapper working-with">
                  <div className="page_heading_section text-center">
                    <h3 className="page_heading text-uppercase d-lg-block d-none title animate-title-two">
                      {universities.title}
                    </h3>
                    <h4 className="text-uppercase page_heading_child subtitle animate-subtitle-two">
                      {universities.title}
                    </h4>
                  </div>
                </div>
              </Observer>
              <div className="wrapper">
                <Row>
                  {universities.cards.map(card => (
                    <Col xs={12} md={6} lg={3} className="pt-4">
                      <div className="flip-card">
                        <div className="flip-card-inner">
                          <div className="flip-card-front justify-content-center p-4">
                            {/* <img src={Boston} alt="yale" className="h-100 w-auto" /> */}
                            <div className="w-100">
                              <Img
                                style={{ maxHeight: '120px', maxWidth: '120px' }}
                                fluid={card.image.childImageSharp.fluid}
                                className="mx-auto"
                              />
                            </div>
                          </div>
                          <div className="flip-card-back d-flex align-items-center">
                            <Card.Body>
                              <Card.Text className="text-bold">{card.text}</Card.Text>
                            </Card.Body>
                          </div>
                        </div>
                      </div>
                    </Col>
                  ))}
                  <Col xs={12} md={6} lg={3} className="pt-4">
                    <Card className="many-more-card">
                      <Card.Body className="d-flex justify-content-center mx-auto flex-column align-content-center">
                        <Card.Text className="text-lowercase text-bold text-appcolor">
                          ...and many more
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
        <Container fluid className="section-three">
          <Observer onChange={this.animateTitleTwo} triggerOnce>
            <Row>
              <Col>
                <div className="wrapper working-with">
                  <div className="page_heading_section text-center">
                    <h3 className="page_heading text-uppercase d-lg-block d-none title animate-title-three">
                      {fields.title}
                    </h3>
                    <h4 className="text-uppercase page_heading_child subtitle animate-subtitle-three">
                      {fields.title}
                    </h4>
                  </div>
                </div>
              </Col>
            </Row>
          </Observer>
          <Observer onChange={this.animateFieldsOfStudy} triggerOnce>
            <Row className="justify-content-center animate-fields-of-study wrapper study-fields-wrapper mx-lg-auto px-lg-0 mt-4 mt-lg-0">
              {fields.cards.map(card => (
                <Col
                  xs={9}
                  lg={3}
                  className="study-field-card d-flex justify-content-center flex-column align-content-center"
                >
                  <Card>
                    <Card.Body className=" mx-auto ">
                      <Card.Text className="text-lowercase text-capitalize study-field text-center">
                        {card.text}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </Observer>
        </Container>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    markdownRemark(frontmatter: { page: { eq: "working-with" } }) {
      frontmatter {
        title
        description
        chart {
          description
          data {
            title
            per
            class
            span
          }
        }
        universities {
          title
          cards {
            image {
              childImageSharp {
                fluid(maxWidth: 512, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            text
          }
        }
        fields {
          title
          cards {
            text
          }
        }
      }
    }
  }
`;
